import React from "react";
import styles from './store.module.css';
import NavigationFooter from "@/components/navigation-footer/navigation-footer";
import NavigationBanner from "@/components/navigation-banner/navigation-banner";
import NavigationHeader from "@/components/navigation-header/navigation-header";

import GoogleMapReact from 'google-map-react';
import Marker from "./Marker";
import { getStoreLocations } from "@/services/users";
import Geocode from 'react-geocode';

const GOOGLE_MAPS_API_KEY = "AIzaSyALjSPP5zz_ax4FLn5j3UYoy84g9LIYxps";

export type StateType = {
    address: string,
    stores: any[],
    stores_to_show: any[],
    items: any[],
    show_filters: boolean,
    map_center_latitude: number,
    map_center_longitude: number,
    user_latitude: number,
    user_longitude: number,
    zoom: number,
    selected_store: any | null,
    show_store_modal: boolean,
}

export default class StoreLocatorPage extends React.Component {
    state: StateType = {
        address: '',
        stores: [],
        stores_to_show: [],
        items: [
            { selected: false, label: '24 Hours' },
            { selected: false, label: 'Full Serve' },
            { selected: false, label: 'ATM' },
            { selected: false, label: 'Gaming Lounge' },
            { selected: false, label: 'Beer' },
            { selected: false, label: 'Kerosene' },
            { selected: false, label: 'Bradley St. Cafe' },
            { selected: false, label: 'Lottery' },
            { selected: false, label: 'Charley Biggs' },
            { selected: false, label: 'Motel' },
            { selected: false, label: 'Convenience' },
            { selected: false, label: 'Pay at the Pump' },
            { selected: false, label: 'DEF' },
            { selected: false, label: 'Propane' },
            { selected: false, label: 'Diesel' },
            { selected: false, label: 'Rooms' },
            { selected: false, label: 'Ethanol Free' },
            { selected: false, label: 'Subway' },
            { selected: false, label: 'Truck Stop'},
        ],
        show_filters: false,
        map_center_latitude: 41.9891,
        map_center_longitude: -79.1131,
        user_latitude: 41.9891,
        user_longitude: -79.1131,
        zoom: 11,
        selected_store: null,
        show_store_modal: false,
    }
    

    private filterStores = async (searchTerm: string) => {
        if (!searchTerm) {
            // Even with no search term, apply any active filters
            const filteredByFeatures = this.applyFeatureFilters(this.state.stores);
            this.setState({ stores_to_show: filteredByFeatures });
            return;
        }

        const searchLower = searchTerm.toLowerCase().trim();
        
        // Handle "City, State" format
        const [cityTerm, stateTerm] = searchLower.split(',').map(term => term.trim());
        
        // First try to find exact matches in our store database
        const filtered = this.state.stores.filter((store) => {
            const cityMatch = store.data.sCity?.toLowerCase().includes(cityTerm);
            const stateMatch = stateTerm ? store.data.sState?.toLowerCase().includes(stateTerm) : true;
            const zipMatch = store.data.sZip?.toLowerCase().includes(searchLower);
            
            return (cityMatch && stateMatch) || zipMatch;
        });

        try {
            let centerLat, centerLon;
            
            if (filtered.length > 0) {
                // If we have exact matches, use the first store's location
                centerLat = parseFloat(filtered[0].data.sLat);
                centerLon = parseFloat(filtered[0].data.sLon);
            } else {
                // If no exact matches, geocode the search term to get coordinates
                Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
                Geocode.setLanguage("en");
                const response = await Geocode.fromAddress(searchTerm);
                centerLat = response.results[0].geometry.location.lat;
                centerLon = response.results[0].geometry.location.lng;
            }

            // Calculate distances for ALL stores from this center point
            const allStores = [...this.state.stores];
            allStores.forEach(store => {
                store.distance = this.calculateDistance(
                    centerLat,
                    centerLon,
                    parseFloat(store.data.sLat),
                    parseFloat(store.data.sLon)
                );
            });

            // Sort all stores by distance
            const sortedStores = allStores.sort((a, b) => a.distance - b.distance);

            // Apply feature filters to the sorted stores
            const filteredByFeatures = this.applyFeatureFilters(
                filtered.length > 0 ? 
                    [...filtered, ...sortedStores.filter(store => !filtered.includes(store))] :
                    sortedStores
            );

            // Update state with new center and filtered stores
            this.setState({
                map_center_latitude: centerLat,
                map_center_longitude: centerLon,
                stores_to_show: filteredByFeatures,
                zoom: this.getZoomForDistance(filteredByFeatures[0]?.distance || 10)
            });

        } catch (error) {
            console.error('Geocoding error:', error);
            // On error, keep current map center and just show all stores sorted by current center
            const stores = [...this.state.stores];
            stores.forEach(store => {
                store.distance = this.calculateDistance(
                    this.state.map_center_latitude,
                    this.state.map_center_longitude,
                    parseFloat(store.data.sLat),
                    parseFloat(store.data.sLon)
                );
            });
            const sortedStores = stores.sort((a, b) => a.distance - b.distance);
            const filteredByFeatures = this.applyFeatureFilters(sortedStores);
            this.setState({ 
                stores_to_show: filteredByFeatures
            });
        }
    }

    private applyFeatureFilters = (stores: any[]): any[] => {
        const selectedFeatures = this.state.items
            .filter(item => item.selected)
            .map(item => item.label);

        if (selectedFeatures.length === 0) {
            return stores;
        }

        return stores.filter(store => {
            // Ensure store.features exists and is an array
            const storeFeatures: string[] = store.features || [];
            // Check if all selected features are present in the store's features
            return selectedFeatures.every(feature => 
                // Handle slight variations in feature names (e.g. "Bradley St. Coffee" vs "Bradley St. Cafe")
                storeFeatures.some((storeFeature: string) => 
                    storeFeature.toLowerCase() === feature.toLowerCase() ||
                    (feature === "Bradley St. Cafe" && storeFeature === "Bradley St. Coffee")
                )
            );
        });
    }

    private getZoomForDistance(distance: number): number {
        if (distance > 100) return 7;
        if (distance > 50) return 8;
        if (distance > 25) return 9;
        if (distance > 10) return 10;
        return 11;
    }

    private calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
        var haversine = require('haversine');
        const start = { latitude: lat1, longitude: lon1 };
        const end = { latitude: lat2, longitude: lon2 };
        return haversine(start, end, {unit: 'mile'});
    }

    componentDidMount = async () => {
        this.state.stores = await getStoreLocations();
        let stores = Object.assign([], this.state.stores);
        stores.forEach((store) => {
            store.distance = this.haversineFunction(store);
        })
        let sorted = stores
            .sort((a, b) => a.distance < b.distance ? -1 : 1);
        // Apply filters to initial store list
        const filteredStores = this.applyFeatureFilters(sorted);
        this.setState({stores_to_show: filteredStores});

        this.getCurrentGeoLocation()
    }

    private getCurrentGeoLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                this.showPosition,
                (error) => {
                    console.error('Error getting location:', error);
                    // Handle specific error cases
                    switch(error.code) {
                        case error.PERMISSION_DENIED:
                            alert("Please enable location services to use this feature.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            alert("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            alert("Location request timed out.");
                            break;
                        default:
                            alert("An unknown error occurred getting your location.");
                            break;
                    }
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                }
            );
        } else {
            alert("Geolocation is not supported by this browser");
        }
    }

    private showPosition = (position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        
        // Update user location and map center
        this.setState({
            user_latitude: latitude,
            user_longitude: longitude,
            map_center_latitude: latitude,
            map_center_longitude: longitude
        }, () => {
            // After state is updated, sort stores by distance from new location
            const stores = [...this.state.stores];
            stores.forEach(store => {
                store.distance = this.calculateDistance(
                    latitude,
                    longitude,
                    parseFloat(store.data.sLat),
                    parseFloat(store.data.sLon)
                );
            });
            
            const sortedStores = stores.sort((a, b) => a.distance - b.distance);
            this.setState({ stores_to_show: sortedStores });
        });
    }

    private setGeoState = (data: {latitude: number, longitude: number}) => {
        this.setState({
            user_latitude: data.latitude,
            user_longitude: data.longitude,
            map_center_latitude: data.latitude,
            map_center_longitude: data.longitude
        }, () => {
            const stores = [...this.state.stores];
            stores.forEach(store => {
                store.distance = this.calculateDistance(
                    data.latitude,
                    data.longitude,
                    parseFloat(store.data.sLat),
                    parseFloat(store.data.sLon)
                );
            });
            
            const sortedStores = stores.sort((a, b) => a.distance - b.distance);
            this.setState({ stores_to_show: sortedStores });
        });
    }

    private haversineFunction(data: any) {
        var haversine = require('haversine');
        let start = {
            latitude: this.state.user_latitude,
            longitude: this.state.user_longitude,
        };
        let end = {
            latitude: parseFloat(data.data.sLat),
            longitude: parseFloat(data.data.sLon),
        };
        const haversineCoords = (haversine(start, end, {unit: 'mile'}));
        if (data.data.sLon === null || data.data.sLat === null) {
            return
        } else {
            data.distance = haversineCoords;
            return data.distance
        }
    }

    private formatPhoneNumber(number: string) {
        const cleaned = number.replace(/[^\d]/g, '');

	    const match = cleaned.match(/^(1|44|61|57|55|49|)?(\d{3})(\d{3})(\d{3,})$/);
	    if (match) {
		    const intlCode = match[1] ? `+${match[1]} ` : '';
		    const areaCode = match[2];
		    const localCode = match[3];
		    return `${intlCode}${areaCode} ${localCode}`;
	    }
	    return number;
    }

    private getSelectedCount(): number {
        let filter = this.state.items.filter((item) => { return item.selected === true })
        return filter.length;
    }

    private openDirections = (store: any) => {
        const address = `${store.data.sAddr1}, ${store.data.sCity}, ${store.data.sState} ${store.data.sZip}`;
        const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`;
        window.open(url, '_blank');
    }

    private callStore = (phone: string) => {
        window.location.href = `tel:${phone}`;
    }

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.navigation_container}>
                    <NavigationBanner />
                    <NavigationHeader opacity_scroll={false} />
                </div>
                
                <div className={styles.contents_container}>
                    <div className={styles.heading_name}>Find a store</div>

                    <div className={styles.store_container}>
                        <div className={styles.left}>
                            <div className={styles.left_container}>
                                <input className={styles.input}
                                    type="text"
                                    placeholder="Enter an address or zip code and press Enter"
                                    value={this.state.address}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        this.setState({address: value});
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.code === 'Enter') {
                                            this.filterStores(this.state.address);
                                        }
                                    }}
                                    onBlur={() => {
                                        if (this.state.address.trim()) {
                                            this.filterStores(this.state.address);
                                        }
                                    }}
                                />
                                <div className={styles.use_current_location_label}>Or use your <span onClick={() => { this.getCurrentGeoLocation() }} className={styles.current_location_label}>current location</span></div>
                                <div className={styles.filter_container}>
                                    <div className={styles.filters_header} onClick={() => {this.setState({show_filters: !this.state.show_filters})}}>
                                        <div className={styles.filters_title}>Filters ({this.getSelectedCount()} applied)</div>
                                        <div className={styles.filters_icon}>{this.state.show_filters ? '-' : '+'}</div>
                                    </div>

                                    { this.state.show_filters &&
                                        <div>
                                            <div className={styles.filter_options}>
                                                { this.state.items.map((item: any, index: number) => {
                                                    return <div key={index} style={{ marginBottom: 8 }}>
                                                        <input type={'checkbox'} checked={item.selected} onChange={(event) => {
                                                            let items = [...this.state.items];
                                                            items[index].selected = !items[index].selected;
                                                            this.setState({ items }, () => {
                                                                // Immediately apply filters when checkbox changes
                                                                const filteredStores = this.applyFeatureFilters(this.state.stores);
                                                                this.setState({ stores_to_show: filteredStores });
                                                            });
                                                        }} />
                                                        <span className={styles.filter_label}>{item.label}</span>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className={styles.nearby_stores}>
                                    {this.state.stores_to_show &&
                                        this.state.stores_to_show.map((store) => {
                                            return (
                                                <StoreLocatorResult
                                                    key={store.data.sId}
                                                    storeInfo={store}
                                                    onPress={() => {
                                                        this.setState({
                                                            map_center_latitude: parseFloat(store.data.sLat),
                                                            map_center_longitude: parseFloat(store.data.sLon),
                                                            selected_store: store,
                                                            show_store_modal: true
                                                        });
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>

                            </div>
                        </div>

                        <div className={styles.right}>
                            <div style={{ height: '100vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ 
                                        key: GOOGLE_MAPS_API_KEY,
                                        libraries: ['places'],
                                        language: 'en'
                                    }}
                                    zoom={this.state.zoom || 11}
                                    center={{lat: this.state.map_center_latitude, lng: this.state.map_center_longitude}}
                                    options={{ 
                                        disableDefaultUI: true,
                                        styles: [
                                            {"featureType":"all","elementType":"labels.text","stylers":[{"color":"#878787"}]},
                                            {"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},
                                            {"featureType":"landscape","elementType":"all","stylers":[{"color":"#f9f5ed"}]},
                                            {"featureType":"road.highway","elementType":"all","stylers":[{"color":"#f5f5f5"}]},
                                            {"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#c9c9c9"}]},
                                            {"featureType":"water","elementType":"all","stylers":[{"color":"#aee0f4"}]}
                                        ] 
                                    }}
                                >
                                    {this.state.stores_to_show.map((store) => (
                                        <Marker
                                            key={store.data.sId}
                                            lat={parseFloat(store.data.sLat)}
                                            lng={parseFloat(store.data.sLon)}
                                            name={store.data.sStoreNumber}
                                            color="#009146"
                                            price={store.gas_price}
                                        />
                                    ))}

                                    <Marker
                                        lat={this.state.user_latitude}
                                        lng={this.state.user_longitude}
                                        color='#000000'
                                    />
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.show_store_modal && this.state.selected_store && (
                    <div className={styles.modal_overlay} onClick={() => this.setState({ show_store_modal: false })}>
                        <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                            <div className={styles.modal_header}>
                                <h2>Store #{this.state.selected_store.data.sStoreNumber}</h2>
                                <button 
                                    className={styles.modal_close}
                                    onClick={() => this.setState({ show_store_modal: false })}
                                >
                                    ×
                                </button>
                            </div>
                            <div className={styles.modal_body}>
                                <div className={styles.store_detail_section}>
                                    <h3>Address</h3>
                                    <p>{this.state.selected_store.data.sAddr1}</p>
                                    {this.state.selected_store.data.sAddr2 && (
                                        <p>{this.state.selected_store.data.sAddr2}</p>
                                    )}
                                    <p>
                                        {this.state.selected_store.data.sCity}, {this.state.selected_store.data.sState} {this.state.selected_store.data.sZip}
                                    </p>
                                </div>

                                {this.state.selected_store.data.sPhone && (
                                    <div className={styles.store_detail_section}>
                                        <h3>Contact</h3>
                                        <p>{this.formatPhoneNumber(this.state.selected_store.data.sPhone)}</p>
                                    </div>
                                )}

                                {this.state.selected_store.features && this.state.selected_store.features.length > 0 && (
                                    <div className={styles.store_detail_section}>
                                        <h3>Features</h3>
                                        <div className={styles.feature_tags}>
                                            {this.state.selected_store.features.map((feature: string, index: number) => (
                                                <span key={index} className={styles.feature_tag}>
                                                    {feature}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {this.state.selected_store.gas_price && (
                                    <div className={styles.store_detail_section}>
                                        <h3>Current Prices</h3>
                                        <p>Gas: ${this.state.selected_store.gas_price}</p>
                                        {this.state.selected_store.diesel_price && (
                                            <p>Diesel: ${this.state.selected_store.diesel_price}</p>
                                        )}
                                    </div>
                                )}

                                <div className={styles.modal_actions}>
                                    <button 
                                        className={styles.primary_button}
                                        onClick={() => this.openDirections(this.state.selected_store)}
                                    >
                                        Get Directions
                                    </button>
                                    {this.state.selected_store.data.sPhone && (
                                        <button 
                                            className={styles.secondary_button}
                                            onClick={() => this.callStore(this.state.selected_store.data.sPhone)}
                                        >
                                            Call Store
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <NavigationFooter />
            </div>
        );
    }
}

export type StoreLocatorResultProps = {
    storeInfo: any;
    onPress: () => void;
    didSelectDirections?: () => void;
    didSelectPhone?: () => void;
}

export class StoreLocatorResult extends React.Component<StoreLocatorResultProps> {
    private address() {
        let address = '';
        
        if (this.props.storeInfo.data.sAddr1) {
            address += this.props.storeInfo.data.sAddr1;
        }

        if (this.props.storeInfo.data.sAddr2) {
            address += ` ${this.props.storeInfo.data.sAddr2}`;
        }

        if (this.props.storeInfo.data.sCity) {
            address += ` ${this.props.storeInfo.data.sCity},`;
        }

        if (this.props.storeInfo.data.sState) {
            address += ` ${this.props.storeInfo.data.sState}`;
        }

        if (this.props.storeInfo.data.sZip) {
            address += ` ${this.props.storeInfo.data.sZip}`;
        }

        return address;
    }

    private formatPhoneNumber(number: string) {
        const cleaned = number.replace(/[^\d]/g, '');

	    const match = cleaned.match(/^(1|44|61|57|55|49|)?(\d{3})(\d{3})(\d{3,})$/);
	    if (match) {
		    const intlCode = match[1] ? `+${match[1]} ` : '';
		    const areaCode = match[2];
		    const localCode = match[3];
		    return `${intlCode}${areaCode} ${localCode}`;
	    }
	    return number;
    }

    render() {
        const { storeInfo, onPress } = this.props;
        const address = this.address();
        const phoneNumber = storeInfo.data.sPhone ? this.formatPhoneNumber(storeInfo.data.sPhone) : '';
        const distance = storeInfo.distance ? `${storeInfo.distance.toFixed(1)} miles` : '';
        
        return (
            <div className={styles.store_result} onClick={onPress}>
                <div className={styles.store_info}>
                    <div className={styles.store_name}>Store #{storeInfo.data.sStoreNumber}</div>
                    <div className={styles.store_address}>{address}</div>
                    {phoneNumber && <div className={styles.store_phone}>{phoneNumber}</div>}
                    {distance && <div className={styles.store_distance}>{distance}</div>}
                    {storeInfo.features && storeInfo.features.length > 0 && (
                        <div className={styles.store_features}>
                            {storeInfo.features.join(' • ')}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}